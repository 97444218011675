import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, Form, H3 } from '../../../components';
import FieldTextInput from '../../../components/FieldTextInput/FieldTextInput';
import { FormattedMessage } from '../../../util/reactIntl';

import css from '../../../components/FieldTextInput/FieldTextInputExample.module.css';

export const StripePayoutInfoForm = ({
  intl,
  onSubmit,
  inProgress,
  initialValues,
  onCloseModal,
}) => {
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const { handleSubmit, values } = formRenderProps;

        const { full_name, bank_account, account_number, pay_now_account } = values;

        const showSubmit =
          (full_name?.trim() && bank_account?.trim() && account_number?.trim()) ||
          pay_now_account?.trim();

        return (
          <Form
            onSubmit={async v => {
              await handleSubmit(v);

              if (onCloseModal) {
                onCloseModal();
              }
            }}
            enforcePagePreloadFor="TripePayoutPage"
          >
            <p className={css.instruction}>
              {intl.formatMessage({
                id: 'StripePayoutInfoForm.payoutInstructionText',
              })}
            </p>
            <FieldTextInput
              className={css.field}
              type="text"
              id="full_name"
              name="full_name"
              label={intl.formatMessage({
                id: 'StripePayoutInfoForm.fullNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'StripePayoutInfoForm.fullNamPlaceholder',
              })}
            />
            <FieldTextInput
              className={css.field}
              type="text"
              id="bank_account"
              name="bank_account"
              label={intl.formatMessage({
                id: 'StripePayoutInfoForm.bankAccountLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'StripePayoutInfoForm.bankAccountPlaceholder',
              })}
            />
            <FieldTextInput
              className={css.field}
              type="text"
              id="account_number"
              name="account_number"
              label={intl.formatMessage({
                id: 'StripePayoutInfoForm.accountNumberLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'StripePayoutInfoForm.accountNumberPlaceholder',
              })}
            />

            <div className={css.or}>or</div>

            <FieldTextInput
              className={css.field}
              type="text"
              id="pay_now_account"
              name="pay_now_account"
              label={intl.formatMessage({
                id: 'StripePayoutInfoForm.payNowAccountLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'StripePayoutInfoForm.payNowAccountPlaceholder',
              })}
            />

            <Button
              type="submit"
              className={css.submitButton}
              inProgress={inProgress}
              disabled={!showSubmit}
            >
              <FormattedMessage id="StripePayoutInfoForm.submitButtonText" />
            </Button>
          </Form>
        );
      }}
    />
  );
};
