import React from 'react';
import classNames from 'classnames';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Modal } from '../../../components';
import { StripePayoutInfoForm } from '../../StripePayoutPage/StripePayoutInfoForm/StripePayoutInfoForm';

import css from './PayoutDetailsModal.module.css';

const PayoutDetailsModal = props => {
  const { id, intl, isOpen, onCloseModal, onManageDisableScrolling, inProgress, onSubmit } = props;

  const classes = classNames(css.root);
  const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="PayoutDetailsModal.titleRowOne" />
      </p>
      <p className={css.modalTitle}>
        <FormattedMessage id="PayoutDetailsModal.titleRowTwo" />
      </p>

      <StripePayoutInfoForm
        intl={intl}
        onSubmit={onSubmit}
        inProgress={inProgress}
        initialValues={{}}
        onCloseModal={onCloseModal}
      />
    </Modal>
  );
};

export default injectIntl(PayoutDetailsModal);
